/* eslint-disable max-lines */
import { ModeMetadata } from '@src/types/control-modes/Modes';

const ModesMetadata: ModeMetadata = {
  'AGC Response': {
    displayName: 'P Follow',
    configFields: [
      {
        configParentProp: 'Power Settings',
        configInput1: {
          label: 'Max Charge (kW)',
        },
        configInput2: {
          label: 'Max Discharge (kW)',
        },
      },
    ],
  },
  'Adaptive Vref': {
    configFields: [
      {
        configParentProp: 'Mode Settings',
        configInput1: {
          label: 'Nominal Voltage',
          quickConfig: true,
        },
        configInput2: {
          label: 'Min Injection (kVAr)',
        },
        configInput3: {
          label: 'Min Absorption (kVAr)',
        },
        configInput4: {
          label: 'Max Injection Source',
        },
        configInput5: {
          label: 'Max Absorption Source',
        },
        configInput6: {
          label: 'Min Voltage Parameter Source',
        },
        configInput7: {
          label: 'Min Voltage Parameter Source',
        },
        configInput8: {
          label: 'Vref Time Constant Source',
        },
        configInput9: {
          label: 'Output Filter Time Constant (s)',
        },
      },
    ],
  },
  'Adjustable Voltage Response': {
    displayName: 'Adjustable Voltage Response',
    configFields: [
      {
        configParentProp: 'Voltage Setpoints',
        configInput1: {
          label: 'Upper Deadband Offset (V)',
        },
        configInput2: {
          label: 'Lower Deadband Offset (V)',
        },
        configInput3: {
          label: 'Nominal Voltage Source',
        },
      },
      {
        configParentProp: 'Droop Setpoints (V/kVAr)',
        configInput1: {
          label: 'Droop Slope High (V/kVAr)',
        },
        configInput2: {
          label: 'Droop Slope Low (V/kVAr)',
        },
        configInput3: {
          label: 'Droop Offset High (kVAr)',
        },
        configInput4: {
          label: 'Droop Offset Low (kVAr)',
        },
      },
      {
        configParentProp: 'Power Settings',
        configInput1: {
          label: 'Max Negative (kVAr)',
        },
        configInput2: {
          label: 'Max Positive (kVAr)',
        },
      },
    ],
  },
  'Configurable Frequency Response': {
    configFields: [
      {
        configParentProp: 'Mode Settings',
        configInput1: {
          label: 'Nominal Frequency',
          quickConfig: true,
        },
        configInput2: {
          label: 'Min Discharge (kW)',
        },
        configInput3: {
          label: 'Min Charge (kW)',
        },
        configInput4: {
          label: 'Max Discharge (kW)',
        },
        configInput5: {
          label: 'Max Charge (kW)',
        },
        configInput6: {
          label: 'Droop Source',
        },
        configInput7: {
          label: 'Deadband Source',
        },
        configInput8: {
          label: 'Droop (%) Default',
        },
        configInput9: {
          label: 'Deadband (Hz) Default',
        },
      },
    ],
  },
  'Configurable Voltage Response': {
    configFields: [
      {
        configParentProp: 'Mode Settings',
        configInput1: {
          label: 'Nominal Voltage',
          quickConfig: true,
        },
        configInput2: {
          label: 'Min Injection (kVAr)',
        },
        configInput3: {
          label: 'Min Absorption (kVAr)',
        },
        configInput4: {
          label: 'Max Injection Source',
        },
        configInput5: {
          label: 'Max Absorption Source',
        },
        configInput6: {
          label: 'Min Voltage Parameter Source',
        },
        configInput7: {
          label: 'Max Voltage Parameter Source',
        },
        configInput8: {
          label: 'Output Filter Time Constant (s)',
        },
      },
    ],
  },
  'Dynamic P': {
    configFields: [
      {
        configParentProp: 'DynamicP',
        configInput1: {
          label: 'P Max Limit',
          quickConfig: true,
        },
        configInput2: {
          label: 'P Min Limit',
          quickConfig: true,
        },
        configInput3: {
          label: 'P Max Source',
        },
        configInput4: {
          label: 'P Min Source',
        },
      },
    ],
  },
  'Dynamic Q': {
    configFields: [
      {
        configParentProp: 'DynamicQ',
        configInput1: {
          label: 'Q Max Limit',
          quickConfig: true,
        },
        configInput2: {
          label: 'Q Min Limit',
          quickConfig: true,
        },
        configInput3: {
          label: 'Q Max Source',
        },
        configInput4: {
          label: 'Q Min Source',
        },
      },
    ],
  },
  'Dynamic S Limit': {
    configFields: [
      {
        configParentProp: 'Dynamic S Limit Settings',
        configInput1: {
          label: 'S Max Limit',
          quickConfig: true,
        },
        configInput2: {
          label: 'S Min Limit',
          quickConfig: true,
        },
        configInput3: {
          label: 'Q Priority',
        },
        configInput4: {
          label: 'S Max Source',
        },
        configInput5: {
          label: 'S Min Source',
        },
      },
    ],
  },
  FFR: {
    configFields: [
      {
        configParentProp: 'FFR Settings',
        configInput1: {
          label: 'Hz Threshold',
        },
        configInput2: {
          label: 'Duration (s)',
        },
        configInput3: {
          label: 'kW Limit Source',
        },
        configInput4: {
          label: 'FFR Deployed Control Source',
        },
        configInput5: {
          label: 'FFR Responsibility Control Source',
        },
      },
    ],
  },
  'FFR Ext Trig': {
    configFields: [
      {
        configParentProp: 'Mode Settings',
        configInput1: {
          label: 'Duration (s)',
          quickConfig: true,
        },
        configInput2: {
          label: 'Ramp Rate Source',
        },
        configInput3: {
          label: 'Setpoint Source',
        },
        configInput4: {
          label: 'Trigger Source',
        },
      },
    ],
  },
  'Frequency Response': {
    configFields: [
      {
        configParentProp: 'Frequency Setpoints',
        configInput1: {
          label: 'Upper Deadband (Hz)',
          quickConfig: true,
        },
        configInput2: {
          label: 'Lower Deadband (Hz)',
          quickConfig: true,
        },
        configInput3: {
          label: 'Nominal Frequency (Hz)',
        },
      },
      {
        configParentProp: 'Droop Setpoints (Hz/kW)',
        configInput1: {
          label: 'Droop Slope Low (Hz/kW)',
          quickConfig: true,
        },
        configInput2: {
          label: 'Droop Slope High (Hz/kW)',
          quickConfig: true,
        },
        configInput3: {
          label: 'Droop Offset Low (kW)',
        },
        configInput4: {
          label: 'Droop Offset High (kW)',
        },
      },
      {
        configParentProp: 'Power Settings',
        configInput1: {
          label: 'Max Discharge (kW)',
          quickConfig: true,
        },
        configInput2: {
          label: 'Max Charge (kW)',
          quickConfig: true,
        },
      },
    ],
  },
  FRRS: {
    configFields: [
      {
        configParentProp: 'FRRS Settings',
        configInput1: {
          label: 'Low Hz Threshold',
          quickConfig: true,
        },
        configInput2: {
          label: 'High Hz Threshold',
          quickConfig: true,
        },
        configInput3: {
          label: 'Duration (s)',
        },
        configInput4: {
          label: 'Max kW Limit Source',
        },
        configInput5: {
          label: 'Min kW Limit Source',
        },
        configInput6: {
          label: 'FRRS Up Deployed Control Source',
        },
        configInput7: {
          label: 'FRRS Down Deployed Control Source',
        },
        configInput8: {
          label: 'FRRS Up Responsibility Control Source',
        },
        configInput9: {
          label: 'FRRS Down Responsibility Control Source',
        },
      },
    ],
  },
  'P Correction': {
    configFields: [
      {
        configParentProp: 'P Correction Settings',
        configInput1: {
          label: 'Max Positive Correction (kW)',
          quickConfig: true,
        },
        configInput2: {
          label: 'Max Negative Correction (kW)',
          quickConfig: true,
        },
        configInput3: {
          label: 'Negative P Available Source',
        },
        configInput4: {
          label: 'Negative Proportional Correction',
          quickConfig: true,
        },
        configInput5: {
          label: 'Positive P Available Source',
        },
        configInput6: {
          label: 'Positive Proportional Correction',
          quickConfig: true,
        },
        configInput7: {
          label: 'Number Samples to Average',
        },
      },
    ],
  },
  'P Follow Ramped': {
    configFields: [
      {
        configParentProp: 'Power Settings',
        configInput1: {
          label: 'Max Charge (kW)',
          quickConfig: true,
        },
        configInput2: {
          label: 'Max Discharge (kW)',
          quickConfig: true,
        },
        configInput3: {
          label: 'Ramp Rate Up (kW/s)',
        },
        configInput4: {
          label: 'Ramp Rate Down (kW/s)',
        },
      },
    ],
  },
  'P Follow Ramped v2': {
    configFields: [
      {
        configParentProp: 'Power Settings',
        configInput1: {
          label: 'Max Charge (kW)',
          quickConfig: true,
        },
        configInput2: {
          label: 'Max Discharge (kW)',
          quickConfig: true,
        },
        configInput3: {
          label: 'Ramp Rate Source',
        },
        configInput4: {
          label: 'Ramp Rate (kW/s) Default',
        },
      },
    ],
  },
  'Power Factor': {
    configFields: [
      {
        configParentProp: 'Power Factor Settings',
        configInput1: {
          label: 'Target PF',
          quickConfig: true,
        },
        configInput2: {
          label: 'Max Q (kVAr)',
        },
        configInput3: {
          label: 'Min Q (kVAr)',
        },
        configInput4: {
          label: 'Use Commanded P?',
        },
        configInput5: {
          label: 'Measured P Source',
        },
      },
    ],
  },
  'Power Smoothing': {
    configFields: [],
  },
  'Power Threshold': {
    configFields: [],
  },
  'Q Correction': {
    configFields: [
      {
        configParentProp: 'Q Correction Settings',
        configInput1: {
          label: 'Max Positive Correction (kVAr)',
          quickConfig: true,
        },
        configInput2: {
          label: 'Max Negative Correction (kVAr)',
          quickConfig: true,
        },
        configInput3: {
          label: 'Number Samples to Average',
        },
      },
    ],
  },
  'Q Follow': {
    configFields: [
      {
        configParentProp: 'VAr Settings',
        configInput1: {
          label: 'Max Negative (kVAr)',
        },
        configInput2: {
          label: 'Max Positive (kVAr)',
        },
      },
    ],
  },
  'Q Follow Ramped': {
    configFields: [
      {
        configParentProp: 'Power Settings',
        configInput1: {
          label: 'Max Negative (kVAr)',
          quickConfig: true,
        },
        configInput2: {
          label: 'Max Positive (kVAr)',
          quickConfig: true,
        },
        configInput3: {
          label: 'Ramp Rate Source',
        },
        configInput4: {
          label: 'Ramp Rate (kVAr/s) Default',
        },
      },
    ],
  },
  'SOC Bias': {
    configFields: [],
  },
  'SOC Rebalance': {
    configFields: [
      {
        configParentProp: 'SOC Rebalance Parameters',
        configInput1: {
          label: 'Deadband Lower Threshold',
          quickConfig: true,
        },
        configInput2: {
          label: 'Deadband Upper Threshold',
          quickConfig: true,
        },
      },
    ],
  },
  'Target SOC': {
    configFields: [
      {
        configParentProp: 'Target SOC Parameters',
        configInput1: {
          label: 'Upper Deadband',
        },
        configInput2: {
          label: 'Lower Deadband',
        },
        configInput3: {
          label: 'Target SOC',
        },
        configInput4: {
          label: 'Discharge kW',
        },
        configInput5: {
          label: 'Charge kW',
        },
      },
    ],
  },
  'Voltage Response': {
    configFields: [
      {
        configParentProp: 'Voltage Setpoints',
        configInput1: {
          label: 'Upper Deadband (V)',
          quickConfig: true,
        },
        configInput2: {
          label: 'Lower Deadband (V)',
          quickConfig: true,
        },
        configInput3: {
          label: 'Nominal Voltage (V)',
        },
      },
      {
        configParentProp: 'Droop Setpoints (V/kVAr)',
        configInput1: {
          label: 'Droop Slope High (V/kVAr)',
          quickConfig: true,
        },
        configInput2: {
          label: 'Droop Slope Low (V/kVAr)',
          quickConfig: true,
        },
        configInput3: {
          label: 'Droop Offset High (kVAr)',
        },
        configInput4: {
          label: 'Droop Offset Low (kVAr)',
        },
      },
      {
        configParentProp: 'Power Settings',
        configInput1: {
          label: 'Max Negative (kVAr)',
        },
        configInput2: {
          label: 'Max Positive (kVAr)',
        },
      },
      {
        configParentProp: 'Output Filter',
        configInput1: {
          label: 'Time Constant (s)',
        },
      },
    ],
  },
  'Voltage Response 2 Step': {
    configFields: [
      {
        configParentProp: 'Primary Slope',
        configInput1: {
          label: 'Lower Max (V)',
        },
        configInput2: {
          label: 'Lower Min (V)',
        },
        configInput3: {
          label: 'Upper Max (V)',
        },
        configInput4: {
          label: 'Upper Min (V)',
        },
        configInput5: {
          label: 'Lower Max (kVAr)',
        },
        configInput6: {
          label: 'Lower Min (kVAr)',
        },
        configInput7: {
          label: 'Upper Max (kVAr)',
        },
        configInput8: {
          label: 'Upper Min (kVAr)',
        },
      },
      {
        configParentProp: 'Secondary Slope',
        configInput1: {
          label: 'Lower Max (V)',
        },
        configInput2: {
          label: 'Lower Min (V)',
        },
        configInput3: {
          label: 'Upper Max (V)',
        },
        configInput4: {
          label: 'Upper Min (V)',
        },
        configInput5: {
          label: 'Lower Max (kVAr)',
        },
        configInput6: {
          label: 'Lower Min (kVAr)',
        },
        configInput7: {
          label: 'Upper Max (kVAr)',
        },
        configInput8: {
          label: 'Upper Min (kVAr)',
        },
      },
      {
        configParentProp: 'Primary Deadband',
        configInput1: {
          label: 'Lower (V)',
        },
        configInput2: {
          label: 'Upper (V)',
        },
        configInput3: {
          label: 'Nominal (V)',
        },
      },
      {
        configParentProp: 'Secondary Deadband',
        configInput1: {
          label: 'Lower (V)',
        },
        configInput2: {
          label: 'Upper (V)',
        },
      },
    ],
  },
};

export default ModesMetadata;
